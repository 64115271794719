// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"
import "./controllers"

import "./src/jquery"
import "./src/jquery_ujs"
import "./src/jquery.autosave.min"
import "./src/best_in_place"

import {} from 'jquery-mask-plugin'
import 'easy-autocomplete'

/*
    Add chart
*/
import "chartkick/chart.js"

import "@fortawesome/fontawesome-free/js/all"

/* Manual import*/ 
import "./src/bootstrap"

import "./src/popover"

import "./src/cookies_eu_banner"

import "bootstrap-datepicker" //old bootstrap datepicker
import "daterangepicker" //advanced datepicker - find a better one if possible

//import "./src/cookies_eu" remove file

import "./src/bootstrapValidator"

import "./src/jquery_event_delegator"

import "./src/utils"

//import "bootstrap-multiselect" - it doesn't support esbuild right now so are are inlcuding directly


//import "bootstrap-select" official version with bs5 is not released yet

/*Better auto complete select of large data with ajax - use this for future*/

$(function() {
    /*console.log("hello world from jquery")        */
})
import "trix"
import "@rails/actiontext"
